import React from 'react';

const REMOVE_SPACES_AND_LEADING_ZEROS = /^(0+|\s)+|\s/g;
const REMOVE_SPACES_AND_LEADING_ZEROS_AND_TRAILING_ZEROS = /^(0+|\s)+|\s|((0+|\s)+$)/g;

class MatchUtil extends React.Component {

  static find(source, property, target) {
    var matchers = [
      MatchUtil.match1.bind(this),
      MatchUtil.match2.bind(this),
      MatchUtil.match3.bind(this),
      MatchUtil.match4.bind(this),
      MatchUtil.match5.bind(this),
      MatchUtil.match6.bind(this),
      MatchUtil.match7.bind(this)
    ];

    for(const matcher of matchers) {
      var result = source.find(c => matcher(c[property], target));
      if(result) {
        return result;
      }
    }
  }

  static match1(n1, n2) {
    return this.match(n1, n2, REMOVE_SPACES_AND_LEADING_ZEROS);
  }

  static match2(n1, n2) {
    var firstCharRemoved = n2.slice(1);
    return this.match(n1, firstCharRemoved, REMOVE_SPACES_AND_LEADING_ZEROS);
  }

  static match3(n1, n2) {
    var lastCharRemoved = n2.slice(0, -1);
    return this.match(n1, lastCharRemoved, REMOVE_SPACES_AND_LEADING_ZEROS);
  }

  static match4(n1, n2) {
    return this.match(n1, n2, REMOVE_SPACES_AND_LEADING_ZEROS_AND_TRAILING_ZEROS);
  }

  static match5(n1, n2) {
    var firstCharRemoved = n2.slice(1);
    return this.match(n1, firstCharRemoved, REMOVE_SPACES_AND_LEADING_ZEROS_AND_TRAILING_ZEROS);
  }

  static match6(n1, n2) {
    var lastCharRemoved = n2.slice(0, -1);
    return this.match(n1, lastCharRemoved, REMOVE_SPACES_AND_LEADING_ZEROS_AND_TRAILING_ZEROS);
  }

  static match7(n1, n2) {
    var cleaned = n2.replace(REMOVE_SPACES_AND_LEADING_ZEROS, "");
    var firstCharRemoved = cleaned.slice(1);
    return this.match(n1, firstCharRemoved, REMOVE_SPACES_AND_LEADING_ZEROS_AND_TRAILING_ZEROS);
  }

  static match(n1, n2, regex) {
    return n1.replace(regex, "") === n2.replace(regex, "");
  }
}

export default MatchUtil;
