import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultilineTextField from '../../../../shared/components/multiline_textfield';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Mapper from '../../../../shared/util/mapper'

class GeneralTab extends React.Component {

  constructor(props) {
    super(props)

    var consignee_details = "basic";
    if(this.props.shipment.consignee) {
      consignee_details = "account";
    }

    this.state = {
      selectable_customer_users: this.props.shipment.customer ? this.props.shipment.customer.users : [],
      consignee_details: consignee_details,
      selectable_consignees: this.props.shipment.customer ? this.props.shipment.customer.consignees : [],
    };
  }

  isValid() {
    return true;
  }

  handleChange(e) {
    this.props.shipment[e.target.name] = e.target.value;
    if(this.props.shipment.transport_type !== "fcl") {
      this.props.shipment.containers = [];
    }
    this.props.updateShipment(this.props.shipment);
  }

  getCustomerAccounts() {
    return this.props.accounts.filter((account) => account.type === 'customer').sort((a, b) => -b.name[0].localeCompare(a.name[0]));
  }

  firstAccountLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleAutoSelectionChanged(property, e, newValue) {
    if(newValue) {
      this.props.shipment[property] = newValue;
    } else {
      this.props.shipment[property] = { id: undefined, users: [], consignees: [] };
    }
    if(property === "customer") {
      this.props.shipment.customer_contact = undefined;
      this.props.shipment.consignee = undefined;
      this.setState({
        selectable_customer_users: this.props.shipment.customer.users,
        selectable_consignees: this.props.shipment.customer.consignees
      });
    }
    this.props.updateShipment(this.props.shipment);
  }

  handleConsigneeDetails(e) {
    this.setState({ consignee_details: e.target.value });
  }

  handleAutoCompleteChange(property, e, newValue) {
    if(newValue) {
      this.state.shipment[property].country = newValue;
    } else {
      this.state.shipment[property].country = { name: "" };
    }
    this.setState({ shipment: this.state.shipment });
  }

  firstUserLetter(option) {
    return option.full_name[0].toUpperCase();
  }

  getUserOptionLabel(value) {
    var user;
    if(this.props.shipment.customer && value.id) {
      user = this.props.shipment.customer.users.find(u => u.id === value.id);
    }
    if(!user) {
      return "";
    }
    return user.full_name;
  }

  getAgentAccounts() {
    return this.props.accounts.filter((account) => account.type === 'agent').sort((a, b) => -b.name[0].localeCompare(a.name[0]));
  }

  getAccountOptionLabel(value) {
    var account;
    if(value.id) {
      account = this.props.accounts.find(a => a.id === value.id);
    }
    if(!account) {
      return "";
    }
    return account.name;
  }

  getConsigneeOptionLabel(value) {
    var account;
    if(this.props.shipment.customer && value.id) {
      account = this.props.shipment.customer.consignees.find(u => u.id === value.id);
    }
    if(!account) {
      return "";
    }
    return account.name;
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Status *</InputLabel>
              <Select
                native
                required
                label="Status *"
                value={this.props.shipment.status}
                name="status"
                onChange={this.handleChange.bind(this)} >
                  {Object.keys(Mapper.shipmentStatuses()).map(status => {
                    return (<option key={"shipment_status_" + status} value={status}>{Mapper.fromShipmentStatus(status)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>
        
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <Autocomplete
              key="id"
              options={this.getCustomerAccounts()}
              groupBy={this.firstAccountLetter.bind(this)}
              getOptionLabel={(option) => this.getAccountOptionLabel(option)}
              value={this.props.shipment.customer}
              disabled={this.props.shipment.id}
              onChange={this.handleAutoSelectionChanged.bind(this, "customer")}
              renderInput={(params) => 
                <TextField {...params}
                  label="Customer"
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              }
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <Autocomplete
              key="id"
              options={this.state.selectable_customer_users.sort((a, b) => -b.full_name[0].localeCompare(a.full_name[0]))}
              groupBy={this.firstUserLetter.bind(this)}
              getOptionLabel={(option) => this.getUserOptionLabel(option)}
              value={this.props.shipment.customer_contact}
              onChange={this.handleAutoSelectionChanged.bind(this, "customer_contact")}
              renderInput={(params) => 
                <TextField {...params}
                  label="Customer contact"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />                      
              }
            />
          </div>
        </div>
        
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <Autocomplete
              key="id"
              options={this.getAgentAccounts()}
              groupBy={this.firstAccountLetter.bind(this)}
              getOptionLabel={(option) => this.getAccountOptionLabel(option)}
              value={this.props.shipment.agent}
              onChange={this.handleAutoSelectionChanged.bind(this, "agent")}
              renderInput={(params) => 
                <TextField {...params}
                  label="Agent"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />                      
              }
            />
          </div>
        </div>
                      
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Reference"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.props.shipment.reference}
              name="reference"
              onChange={this.handleChange.bind(this)} 
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Incoterms *</InputLabel>
              <Select
                native
                required
                label="Incoterms *"
                value={this.props.shipment.incoterms}
                name="incoterms"
                onChange={this.handleChange.bind(this)} >
                  <option key={"incoterms_empty"} value=""></option>
                  {Object.keys(Mapper.incoterms()).map(incoterms => {
                    return (<option key={"incoterms_"+incoterms} value={incoterms}>{Mapper.fromIncoterms(incoterms)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>
          
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Consignee Type</InputLabel>
              <Select
                native
                label="Consignee Type"
                value={this.props.shipment.consignee_type}
                name="consignee_type"
                onChange={this.handleChange.bind(this)} >
                  <option key={"consignee_type_empty"} value=""></option>
                  {Object.keys(Mapper.consigneeTypes()).map(consignee_type => {
                    return (<option key={"consignee_type_" + consignee_type} value={consignee_type}>{Mapper.fromConsigneeType(consignee_type)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="list-row-item">
          <span className="list-row-item-header-text">Consignee details</span>

          <div className="row row-small">
            <div className="col-md">
              <FormControl>
                <RadioGroup
                  row
                  name="consignee_details"
                  value={this.state.consignee_details}
                  onChange={this.handleConsigneeDetails.bind(this)}
                >
                  <FormControlLabel  value="basic" control={<Radio />} label="Basic" />
                  <FormControlLabel value="account" control={<Radio />} label="Account" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          
          {this.state.consignee_details === "basic" &&
            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <TextField
                  label="Consignee name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={this.props.shipment.consignee_name}
                  name="consignee_name"
                  onChange={this.handleChange.bind(this)} 
                />
              </div>
            </div>
          }

          {this.state.consignee_details === "basic" &&
            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <TextField
                  label="Consignee phone"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={this.props.shipment.consignee_phone}
                  name="consignee_phone"
                  onChange={this.handleChange.bind(this)} 
                />
              </div>
              <div className="col-md-6 col-lg-4">
                <TextField
                  label="Consignee email"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={this.props.shipment.consignee_email}
                  name="consignee_email"
                  onChange={this.handleChange.bind(this)} 
                />
                </div>
              </div>
            }
            
            {this.state.consignee_details === "account" &&
              <div className="row row-small">
                <div className="col-md-6 col-lg-4">
                  <Autocomplete
                    key="id"
                    options={this.state.selectable_consignees}
                    groupBy={this.firstAccountLetter.bind(this)}
                    getOptionLabel={(option) => this.getConsigneeOptionLabel(option)}
                    value={this.props.shipment.consignee}
                    onChange={this.handleAutoSelectionChanged.bind(this, "consignee")}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Consignee account"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    }
                  />
                </div>
              </div>
            }
        </div>

        
        <div className="row row-small">
          <div className="col-md-12 col-lg-8">
            <MultilineTextField
              label="Internal notes"
              variant="outlined"
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.props.shipment.internal_notes}
              name="internal_notes"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.props.shipment.internal_notes !== undefined,
              }}
            />
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default GeneralTab;
