import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import RowDataTable from './row_data_table';
import Mapper from '../util/mapper'
import Converter from '../util/converter'
import TableUtil from '../util/table_util'

class PackagesTable extends React.Component {

  constructor(props) {
    super(props)

    this.rowTableRef = React.createRef();
  }

  getPackagesRows() {
    var packages = [];
    var data = this.props.data;
    
    if(!data) {
      return;
    }
    
    if(!data.colli) {
      return { data: [], totalElements: 0 };
    }
    
    data.colli.forEach((_package, index) => {
      packages.push({
        ... _package,
        index: index,
        id: `Package ${index + 1}`,
        number: _package.package_number,
        orgHierarchy: [ `Package ${index + 1}` ]
      })

      if(_package.parts) {
        _package.parts.forEach((part, part_index) => {
          packages.push({
            ... part,
            id: `\xa0\xa0\xa0\xa0 Part ${part_index + 1}`,
            number: part.part_number,
            orgHierarchy: [ `Package ${index + 1}`, `Part ${part_index + 1}` ]
          });
          
        });
      }
    });

    return { data: packages, totalElements: packages.length, ready: true };
  }

  getPackagesHeaders() {
    var headers = [];
    
    if(this.props.editable) {
      headers.push( {
        headerName: "Actions",
        field: "actions",
        cellRenderer: (params) => this.getPackagesActionButtons(params),
        minWidth: 125,
        width: 125,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true
      });
    }

    if(this.includesHeader("number")) {
      headers.push(TableUtil.getTextColumn("number", "Number"));
    }

    if(this.includesHeader("loading_number")) {
      headers.push(TableUtil.getTextColumn("loading_number", "Loading number"));
    }

    if(this.includesHeader("order_number")) {
      headers.push(TableUtil.getTextColumn("order_number", "Order number"));
    }

    if(this.includesHeader("quantity")) {
      headers.push(TableUtil.getNumberColumn("quantity", "Quantity"));
    }

    if(this.includesHeader("unloading_quantity")) {
      headers.push(TableUtil.getNumberColumn("unloading_quantity", "Unloading quantity"));
    }

    if(this.includesHeader("unloading_status")) {
      headers.push(TableUtil.getSetColumn(
        "unloading_status",
        "Unloading status",
        (params) => !params.getValue() ? <span/> : <span className={"badge " + Mapper.toLoadingStatusBadge(params.getValue())} >{Mapper.fromLoadingStatus(params.getValue())}</span>,
        Object.keys(Mapper.loadingStatuses()),
        (value) => Mapper.fromLoadingStatus(value)
      ));
    }

    if(this.includesHeader("scan_unloading_status")) {
      headers.push(TableUtil.getSetColumn(
        "unloading_status",
        "Unloading status",
        (params) => !params.getValue() ? <span/> : <span className={"badge " + Mapper.toScanLoadingStatusBadge(params.getValue())} >{Mapper.fromScanLoadingStatus(params.getValue())}</span>,
        Object.keys(Mapper.scanLoadingStatuses()),
        (value) => Mapper.fromScanLoadingStatus(value)
      ));
    }

    if(this.includesHeader("loading_quantity")) {
      headers.push(TableUtil.getNumberColumn("loading_quantity", "Loading quantity"));
    }

    if(this.includesHeader("loading_status")) {
      headers.push(TableUtil.getSetColumn(
        "loading_status",
        "Loading status",
        (params) => !params.getValue() ? <span/> : <span className={"badge " + Mapper.toLoadingStatusBadge(params.getValue())} >{Mapper.fromLoadingStatus(params.getValue())}</span>,
        Object.keys(Mapper.loadingStatuses()),
        (value) => Mapper.fromLoadingStatus(value)
      ));
    }

    if(this.includesHeader("scan_loading_status")) {
      headers.push(TableUtil.getSetColumn(
        "loading_status",
        "Loading status",
        (params) => !params.getValue() ? <span/> : <span className={"badge " + Mapper.toScanLoadingStatusBadge(params.getValue())} >{Mapper.fromScanLoadingStatus(params.getValue())}</span>,
        Object.keys(Mapper.scanLoadingStatuses()),
        (value) => Mapper.fromScanLoadingStatus(value)
      ));
    }

    if(this.includesHeader("reference")) {
      headers.push(TableUtil.getTextColumn("reference", "Reference"));
    }

    if(this.includesHeader("part_price")) {
      headers.push(TableUtil.getNumberColumn("part_price", "Part price", (params) => Converter.formatNumber(params.value, 2)));
    }

    if(this.includesHeader("country_of_origin")) {
      headers.push(TableUtil.getTextColumn("country_of_origin", "Origin"));
    }

    if(this.includesHeader("description")) {
      headers.push(TableUtil.getTextColumn("description", "Description"));
    }
  
    if(this.includesHeader("arrival_date")) {
      headers.push(TableUtil.getTextColumn("arrival_date", "Arrival date", (params) => Converter.toDate(params.value), { disable: true }));
    }

    if(this.includesHeader("ready_date")) {
      headers.push(TableUtil.getTextColumn("ready_date", "Ready date", (params) => Converter.toDate(params.value), { disable: true }));
    }

    if(this.includesHeader("dispatch_date")) {
      headers.push(TableUtil.getTextColumn("dispatch_date", "Dispatch date", (params) => Converter.toDate(params.value), { disable: true }));
    }

    return headers;
  }

  autoGroupColumnDef() {
    return {
      headerName: "#",
      cellRendererParams: {
        suppressCount: true
      },
      minWidth: 135,
      filter: "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
      },
      floatingFilter: true
    };
  }

  getPackagesActionButtons(params) {
    if(params.data.orgHierarchy.length > 1) {
      return <div/>;
    }
    
    return (
      <div className='table-cell-button-container'>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onEdit(params.data.index)}
        >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onClone(params.data.index)}
        >
          <Icon>content_copy</Icon>
        </IconButton>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onDelete(params.data.index)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    );
  }

  getLoadingFilter(params) {
    if (!params.value) {
        return '(Blanks)';
    } else if (params.value === '(Select All)') {
        return params.value;
    } else {
      return Mapper.fromLoadingStatus(params.value);
    }
  }

  includesHeader(name) {
    return this.props.headers && this.props.headers.includes(name);
  }

  addFilter(property, value) {
    this.rowTableRef.current.addFilter(property, value);
  }

  render() {
    return (
      <RowDataTable
        ref={this.rowTableRef}
        name={this.props.name}
        rowData={this.getPackagesRows()}
        columnDefs={this.getPackagesHeaders()}
        treeData={true}
        getDataPath={(data) => { return data.orgHierarchy; }}
        groupDefaultExpanded={this.props.groupDefaultExpanded !== undefined ? this.props.groupDefaultExpanded : true}
        autoGroupColumnDef={this.autoGroupColumnDef()}
        overlayNoRowsTemplate={"There are no packages"}
  />
    );
  }
}

export default PackagesTable;
